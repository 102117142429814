import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

interface AllContent {
  content: any;
  peopleGroup: string;
  loading: boolean;
  company: string;
  activeSection: string;
  data: object;
  loadItem: (() => void) | null;
  reload: boolean;
}

interface AllContentContextType {
  allContent: AllContent;
  setAllContent: Dispatch<SetStateAction<AllContent>>;
}

export const AllContentContext = createContext<
  AllContentContextType | undefined
>(undefined);

interface AllContentContextProviderProps {
  children: ReactNode;
}

const AllContentContextProvider: React.FC<AllContentContextProviderProps> = (
  props
) => {
  const [allContent, setAllContent] = useState<AllContent>({
    content: [],
    peopleGroup: "",
    company: "",
    loading: false,
    activeSection: "welcomeScreen",
    data: {},
    loadItem: null,
    reload: false,
  });

  useEffect(() => {
    if (allContent.loading) {
      setTimeout(() => {
        setAllContent((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }, 500);

      // Cleanup the timeout if the component unmounts before the timeout completes
    }
  }, [allContent.loading]);
  
  useEffect(() => {
    if (allContent.reload) {
   
      if (allContent.loadItem) {
        setAllContent((prevState) => ({
          ...prevState,
          content: [],
          peopleGroup: "",
          company: "",
          activeSection: "welcomeScreen",
          data: {},
        }));
        allContent.loadItem();
        localStorage.clear()
      }
      const timeoutId = setTimeout(() => {
        setAllContent((prevState) => ({
          ...prevState,
          reload: false,
        }));
      }, 500);

      // Cleanup the timeout if the component unmounts before the timeout completes
      return () => clearTimeout(timeoutId);
    }
  }, [allContent.reload]);
  return (
    <AllContentContext.Provider value={{ allContent, setAllContent }}>
      {props.children}
    </AllContentContext.Provider>
  );
};

export default AllContentContextProvider;
