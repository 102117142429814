import { useReducer, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "src/config/axios";
import { AllContentContext } from "src/context/AllContentContext";
import {
  detailsInitialState,
  detailsReducer,
} from "src/reducers/detailsReducer";
import Layout from "../UI/layout/Layout";
import Loading from "../UI/layout/shared/loading/Loading";
import GroupSelection from "./groupSelection/GroupSelection";
import CustomerForm from "./customerForm/CustomerForm";
import CompanyFilledForm from "./companyFilledForm/CompanyFilledForm";
import ConfirmAndSubmit from "./confirmAndSubmit/ConfirmAndSubmit";
import ErrorPage from "../UI/layout/shared/errorPage/ErrorPage";
import WelcomeScreen from "./welcomeScreen/WelcomeScreen";
import SessionSelection from "./sessionsSelection/SessionSelection";

export default function Home(): JSX.Element {
  const { slug } = useParams(); // Extract slug from URL parameters
  const [state, dispatch] = useReducer(detailsReducer, detailsInitialState);
  console.log(slug);
  useEffect(() => {
    setTimeout(() => {
      loadItems();
    }, 1000);
  }, []);


  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("disclaimer");
    };
  
    window.addEventListener("beforeunload", clearLocalStorage);
    return () => window.removeEventListener("beforeunload", clearLocalStorage);
  }, []);
  
  const context = useContext(AllContentContext);
  if (!context) {
    return <div>Error: AllContentContext is not available</div>;
  }

  const { allContent, setAllContent } = context;

  const loadItems = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const res = await axios.get(`get-content/${slug}`); // Use the slug in the API call
      dispatch({ type: "FETCH_SUCCESS", payload: res.data });
      setAllContent((prevState) => ({
        ...prevState,
        content: res.data,
        loadItem: loadItems,
      }));
      if (
        res.data &&
        res.data.activity &&
        res.data.activity.welcomeScreen &&
        res.data.activity.welcomeScreen.showWelcomeScreen === "No"
      ) {
        setAllContent((prevState) => ({
          ...prevState,
          activeSection: "groupSelection",
        }));
      }
    } catch (error) {
      dispatch({ type: "FETCH_FAILURE", payload: error });
    }
  };
  interface EventBreakout {
    _id: string;
    day: number;
    title: string;
    breakoutId: string;
    sortOrder: number;
    sessionTitle: string;
  }
  interface ContentItem {
    activity?: {
      welcomeScreen?: {
        showWelcomeScreen?: string;
        screenHeading?: string;
        screenMessage?: string;
      };
      content?: {
        showAheadLogo?: string;
        aheadLogo?: string;
        showClientLogo?: string;
        clientLogo?: string;
        showDescription?: string;
        description?: string;
        showCopyrightMessage?: string;
        copyrightMessage?: string;
        showSocialChannels?: string;
        submissionStatus?: string;
      };
      eventBreakouts?: EventBreakout[];
      formScreen?: {
        showCompleteForm?: string[];
        lighterFormFields: string[];
        heading?: string;
        message?: string;
      };
    };
  }
  if (!context) {
    return <div>Error: context is not available</div>;
  }
  const details = allContent.content as ContentItem;

  return (
    <>
      {state.isFetching && <Loading />}
      {state.hasError ? (
        <ErrorPage />
      ) : (
        <div className="xl:container mx-auto">
          {details.activity &&
            details.activity.content &&
            details.activity.content.submissionStatus === "Not Started" && (
              <Layout className="center">
                <div className="flex  items-center justify-center ">
                  <p className="heading font-lato text-2xl text-magenta-800 font-base ">
                    Submission is not started yet.
                  </p>
                </div>
              </Layout>
            )}
          {details.activity &&
            details.activity.content &&
            details.activity.content.submissionStatus === "Started" && (
              <Layout
                className={
                  details.activity &&
                  details.activity.welcomeScreen &&
                  details.activity.welcomeScreen.showWelcomeScreen === "Yes" &&
                  allContent.activeSection === "welcomeScreen"
                    ? "center"
                    : ""
                }
              >
                <>
                  {details.activity &&
                    details.activity.welcomeScreen &&
                    details.activity.welcomeScreen.showWelcomeScreen ===
                      "Yes" &&
                    allContent.activeSection === "welcomeScreen" && (
                      <WelcomeScreen />
                    )}
                  {allContent.peopleGroup === "" &&
                    allContent.activeSection === "groupSelection" && (
                      <GroupSelection />
                    )}
                  {details.activity &&
                    details.activity.formScreen &&
                    details.activity.formScreen.showCompleteForm?.includes(
                      allContent.peopleGroup
                    ) &&
                    allContent.activeSection === "formFilling" && (
                      <CustomerForm />
                    )}
                  {details.activity &&
                    details.activity.formScreen &&
                    !details.activity.formScreen.showCompleteForm!.includes(
                      allContent.peopleGroup
                    ) &&
                    allContent.activeSection === "formFilling" &&
                    allContent.peopleGroup !== "" && <CompanyFilledForm />}
                  {allContent.activeSection === "sessions" && (
                    <SessionSelection />
                  )}
                  {allContent.activeSection === "detailsDisplay" && (
                    <ConfirmAndSubmit />
                  )}
                </>
              </Layout>
            )}
          {details.activity &&
            details.activity.content &&
            details.activity.content.submissionStatus === "Ended" && (
              <Layout className="center">
                <div className="flex  items-center justify-center ">
                  <p className="heading font-lato text-2xl text-magenta-800 font-base ">
                    Submission is closed.
                  </p>
                </div>
              </Layout>
            )}
        </div>
      )}
    </>
  );
}
