import React, { ForwardedRef } from 'react';
import { Form } from 'react-bootstrap';
import { Field, ErrorMessage, FieldProps } from 'formik';
import Disclaimer from 'src/components/modules/disclaimer/Disclaimer';


type CMSFormCheckBoxGroupProps = {
    name: string;
    type?: 'checkbox';
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    className?: string;
    variant?: string;
    value?: any;
    error?: string;
    children?: React.ReactNode;
    label?: string;
    required?: boolean;
    touched?: boolean;
    errors?: Record<string, string>;
    validate?: (value: any) => string | undefined;
    as?: string;
    md?: number;
    rows?: number;
    controlId?: string;
    options?: any;
    disabled?: boolean;
    readOnly?: boolean;
    ref?: ForwardedRef<HTMLInputElement>;
    id?: string;
    checked?: boolean;
};

const CMSFormCheckBoxGroup: React.FC<CMSFormCheckBoxGroupProps> = ({
    name,
    type = 'checkbox',
    placeholder,
    onChange,
    onBlur,
    className = '',
    variant,
    value,
    error,
    children,
    label,
    required,
    touched,
    errors,
    validate,
    as,
    md,
    rows,
    controlId,
    options,
    disabled,
    readOnly,
    ref,
    id,
    checked,
    ...props
}) => {

    return (
        <React.Fragment>
            <div className="form-group position-relative">
                <Field name={name} validate={validate} {...props}>
                    {({ field, form }: FieldProps) => (
                        <>
                            <Form.Check className='flex items-center'>
                                <Form.Check.Input
                                    id={id}
                                    name={name}
                                    ref={ref}

                                    type={type}
                                    onBlur={onBlur}
                                    value={value}
                                    onChange={onChange}

                                    required={required}
                                    disabled={disabled}
                                    readOnly={readOnly}
                                    placeholder={placeholder}
                                    checked={checked}
                                />
                                <span className='ms-2 flex'>
                                    <Form.Check.Label htmlFor={id} className='noselect'>{label}</Form.Check.Label>
                                    <span className='ms-2 mb-0'>

                                        <Disclaimer />
                                    </span>
                                </span>
                            </Form.Check>

                            {form.touched[name] && form.errors[name] && (
                                <span id={`${name}-error`} className="form-error">
                                    <ErrorMessage name={name} />
                                </span>
                            )}
                        </>
                    )}
                </Field>
            </div>
        </React.Fragment>
    );
};

export default CMSFormCheckBoxGroup;
