import React, { FC, useState, useContext } from "react";
import { Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import CMSFormInput from "../../UI/layout/shared/formFields/CMSFormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListSquares } from "@fortawesome/free-solid-svg-icons";
import CMSFormSelect from "../../UI/layout/shared/formFields/CMSFormSelect";
import CMSFormTelephone from "src/components/UI/layout/shared/formFields/CMSFormTelephone";
import { AllContentContext } from "src/context/AllContentContext";
import Loading from "src/components/UI/layout/shared/loading/Loading";
import { title } from "process";
import CMSFormCheckBoxGroup from "src/components/UI/layout/shared/formFields/CMSFormCheckBoxGroup";
import { Alert } from "react-bootstrap";

const CustomerForm: FC = () => {
  const [error, setError] = useState(false);
  const context = useContext(AllContentContext);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState<boolean>(() => {
    return localStorage.getItem("disclaimer") === "true";
  });

  interface EventBreakout {
    _id: string;
    day: number;
    title: string;
    breakoutId: string;
    sortOrder: number;
    sessionTitle: string;
  }
  interface ContentItem {
    activity?: {
      title?: string;
      content?: {
        subTitle?: string;
      };
      eventBreakouts?: EventBreakout[];
      formattedDate?: string;
      location?: {
        name?: string;
        country?: string;
      };
      peopleGroups?: [{ title?: string }];
      client?: {
        name?: string;
        logo?: string;
      };
      disclaimer?: { hasDisclaimer?: string; heading: string; disclaimer?: string; };
      formScreen?: {
        showCompleteForm?: string[];
        completeFormFields?: string[];
        completeFormRequiredFields?: string[];
        lighterFormFields?: string[];
        lighterFormRequiredFields?: string[];
        heading?: string;
        message?: string;
      };
      breakoutSettings?: {
        showBreakoutScreen?: string;
        heading?: string;
        message?: string;
        validationType: string;
        peopleGroups?: string[];
      };
      personTitles?: [{ _id: string; value?: string }];
      countries?: [{ name?: string }];
      partner?: [{ name?: string; logo?: string }];
      sponsor?: [{ name?: string; logo?: string }];
    };
  }

  interface AllContentData {
    title: string;
    firstName: string;
    lastName: string;
    designation: string;
    sector: string;
    company: string;
    country: string;
    mobile: string;
    email: string;
  }

  interface AllContent {
    content: ContentItem;
    data: AllContentData;
  }

  if (!context) {
    return <div>Error: context is not available</div>;
  }

  const { allContent, setAllContent } = context;
  const details = allContent.content as ContentItem;
  const data = allContent.data as AllContentData;
  const firstDetail = details;
  if (!details.activity?.formScreen?.completeFormFields) {
    return <div>Error: form fields are not defined</div>;
  }
  if (!details.activity?.formScreen?.completeFormRequiredFields) {
    return <div>Error: required fields are not defined</div>;
  }
  const completeFormFields = details.activity.formScreen.completeFormFields;
  const completeFormRequiredFields =
    details.activity.formScreen.completeFormRequiredFields;
  const initialValues: { [key: string]: string } = {};
  const validationSchemaFields: Record<string, yup.AnySchema> = {};
  const getDisplayName = (field: string): string => {
    switch (field) {
      case "firstName":
        return "First Name"; // "First Name"
      case "lastName":
        return "Last Name"; // "Last Name"
      case "company":
        return "Company/Organization"; // "Company/Organization"
      case "title":
        return "Title";
      case "designation":
        return "Job Title/Position";
      case "sector":
        return "Sector";
      case "email":
        return "Business Email";
      case "country":
        return "Country";
      case "mobile":
        return "Phone Number";
      default:
        return field; // Return the field name itself if no match found
    }
  };
  completeFormFields.forEach((field) => {
    initialValues[field] = data[field as keyof AllContentData] || "";

    if (
      details.activity?.disclaimer?.hasDisclaimer === "Add as Checkbox in Form" &&
      details.activity?.disclaimer?.disclaimer
    ) {
      validationSchemaFields["disclaimer"] = yup
        .boolean()
        .oneOf([true], "Please accept the terms and conditions")
        .required("Please accept the terms and conditions");
    }

    if (field === "email") {
      if (completeFormRequiredFields?.includes(field)) {
        validationSchemaFields[field] = yup
          .string()
          .required(`Enter ${getDisplayName(field)}`)
          .email("Enter a valid email address");
      }
    } else {
      if (completeFormRequiredFields?.includes(field)) {
        validationSchemaFields[field] = yup
          .string()
          .required(`Enter ${getDisplayName(field)}`);
      }
    }
  });


  const validationSchema = yup.object().shape(validationSchemaFields);
  console.log(disclaimerAccepted)
  interface Item {
    name: string;
  }
  interface Item2 {
    _id: string;
    value: string;
  }
  interface FormattedItem {
    label: string;
    value: string;
  }

  const handleFormatArray = (item: Item[]): FormattedItem[] => {
    const array: FormattedItem[] = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].name, value: item[i].name });
    }
    return array;
  };
  const handleFormatTitleArray = (item: Item2[]): FormattedItem[] => {
    const array: FormattedItem[] = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].value, value: item[i].value });
    }
    return array;
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <>
      {allContent.loading && <Loading />}
      <div className="formgroup_heading text-magenta-800 font-lato font-light">
        <FontAwesomeIcon icon={faListSquares} className="fa mr-2" />{" "}
        {details.activity && details.activity.formScreen && (
          <>
            {details.activity.formScreen.heading}{" "}
            {allContent.peopleGroup && ` (${allContent.peopleGroup})`}
            {typeof details.activity.formScreen.message === "string" ? (
              <p
                className="formgroup_subheading text-black mb-10 -mt-3 font-lato"
                dangerouslySetInnerHTML={{
                  __html: details.activity.formScreen.message,
                }}
              />
            ) : null}
          </>
        )}
      </div>
      <Formik
        initialValues={{
          title: data.title ? data.title : "",
          firstName: data.firstName ? data.firstName : "",
          lastName: data.lastName ? data.lastName : "",
          designation: data.designation ? data.designation : "",
          company: data.company ? data.company : "",
          sector: data.sector ? data.sector : "",
          country: data.country ? data.country : "",
          mobile: data.mobile ? data.mobile : "",
          email: data.email ? data.email : "",
          disclaimer: disclaimerAccepted,
        }}
        validationSchema={validationSchema}
        enableReinitialize={false}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values) => {
          const { disclaimer, ...rest } = values;
          if (!error) {
            if (
              details.activity?.breakoutSettings?.showBreakoutScreen ===
              "Yes (to all People Groups)" &&
              (details.activity.eventBreakouts?.length ?? 0) > 0
            ) {
              setTimeout(() => {
                const data = { ...allContent.data, ...values };
                setAllContent((prevState) => ({
                  ...prevState,
                  loading: true,
                  data: data,
                  activeSection: "sessions",
                }));
                console.log(allContent.data);
              }, 400);
            } else if (
              details.activity?.breakoutSettings?.showBreakoutScreen ===
              "Yes (only to selected People Groups)" &&
              (details.activity.eventBreakouts?.length ?? 0) > 0 &&
              details.activity?.breakoutSettings?.peopleGroups?.includes(
                allContent.peopleGroup
              )
            ) {
              setTimeout(() => {
                const data = { ...allContent.data, ...values };
                setAllContent((prevState) => ({
                  ...prevState,
                  loading: true,
                  data: data,
                  activeSection: "sessions",
                }));
                console.log(allContent.data);
              }, 400);
            } else {
              setTimeout(() => {
                const data = { ...allContent.data, ...values };
                setAllContent((prevState) => ({
                  ...prevState,
                  loading: true,
                  data: data,
                  activeSection: "detailsDisplay",
                }));
                console.log(allContent.data);
              }, 400);
            }
          }
        }}
      >
        {(form) => (
          <Form onSubmit={form.handleSubmit}>
            <div className="grid grid-cols-2 gap-x-7 gap-y-3 mx-10">
              {completeFormFields && completeFormFields.includes("title") && (
                <div>
                  <CMSFormSelect
                    name="title"
                    label="Title"
                    onKeyPress={handleKeyPress}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      form.handleChange(e);
                      setAllContent((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          title: e.target.value,
                        },
                      }));
                    }}
                    placeholder={{ label: "Select title", value: "" }}
                    form={form}
                    required={
                      completeFormRequiredFields.includes("title") ? true : false
                    }
                    options={handleFormatTitleArray(
                      (details.activity?.personTitles?.filter(
                        (title) => title.value
                      ) ?? []) as Item2[]
                    )}
                  />
                </div>
              )}
              {completeFormFields && completeFormFields.includes("firstName") && (
                <div>
                  <CMSFormInput
                    name="firstName"
                    label="First Name"
                    form={form}
                    required={
                      completeFormRequiredFields.includes("firstName")
                        ? true
                        : false
                    }
                    onKeyPress={handleKeyPress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      form.handleChange(e);
                      setAllContent((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          firstName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              )}
              {completeFormFields && completeFormFields.includes("lastName") && (
                <div>
                  <CMSFormInput
                    name="lastName"
                    label="Last Name"
                    form={form}
                    required={
                      completeFormRequiredFields.includes("lastName")
                        ? true
                        : false
                    }
                    onKeyPress={handleKeyPress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      form.handleChange(e);
                      setAllContent((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              )}
              {completeFormFields &&
                completeFormFields.includes("designation") && (
                  <div>
                    <CMSFormInput
                      name="designation"
                      label="Job Title/Position "
                      form={form}
                      required={
                        completeFormRequiredFields.includes("designation")
                          ? true
                          : false
                      }
                      onKeyPress={handleKeyPress}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        form.handleChange(e);
                        setAllContent((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            designation: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                )}
              {completeFormFields && completeFormFields.includes("company") && (
                <div>
                  <CMSFormInput
                    name="company"
                    label="Company/Organization"
                    form={form}
                    disabled={allContent.company != "" ? true : false}
                    required={
                      completeFormRequiredFields.includes("company")
                        ? true
                        : false
                    }
                    onKeyPress={handleKeyPress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      form.handleChange(e);
                      setAllContent((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          company: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              )}
              {completeFormFields && completeFormFields.includes("sector") && (
                <div>
                  <CMSFormInput
                    name="sector"
                    label="Sector/ Industry"
                    form={form}
                    required={
                      completeFormRequiredFields.includes("sector")
                        ? true
                        : false
                    }
                    onKeyPress={handleKeyPress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      form.handleChange(e);
                      setAllContent((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          sector: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              )}
              {completeFormFields && completeFormFields.includes("country") && (
                <div>
                  <CMSFormSelect
                    name="country"
                    label="Country"
                    onKeyPress={handleKeyPress}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      form.handleChange(e);
                      setAllContent((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          country: e.target.value,
                        },
                      }));
                    }}
                    placeholder={{ label: "Select Country", value: "" }}
                    form={form}
                    required={
                      completeFormRequiredFields.includes("country")
                        ? true
                        : false
                    }
                    options={handleFormatArray(
                      (details.activity?.countries?.filter(
                        (country) => country.name
                      ) ?? []) as Item[]
                    )}
                  />
                </div>
              )}
              {completeFormFields && completeFormFields.includes("mobile") && (
                <div>
                  <CMSFormTelephone
                    name="mobile"
                    label="Phone Number"
                    form={form}
                    required={
                      completeFormRequiredFields.includes("mobile")
                        ? true
                        : false
                    }
                    errorState={error}
                    setErrorState={setError}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      form.handleChange(e);
                      setAllContent((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          mobile: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              )}
              {completeFormFields && completeFormFields.includes("email") && (
                <div>
                  <CMSFormInput
                    name="email"
                    label="Business Email"
                    form={form}
                    onKeyPress={handleKeyPress}
                    required={
                      completeFormRequiredFields.includes("email") ? true : false
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      form.handleChange(e);

                      setAllContent((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          email: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              )}
              {details.activity?.disclaimer &&
                details.activity.disclaimer.hasDisclaimer &&
                details.activity.disclaimer.hasDisclaimer === "Add as Checkbox in Form" ? (
                <CMSFormCheckBoxGroup
                  checked={disclaimerAccepted}
                  type="checkbox"
                  name="disclaimer"
                  id="check"
                  onBlur={(e) => {
                    form.handleBlur(e);
                  }}
                  onChange={(e) => {
                    form.handleChange(e);
                    setDisclaimerAccepted(e.target.checked);
                    console.log(e.target.checked)
                    localStorage.setItem("disclaimer", e.target.checked.toString());
                  }}
                  label={`I Accept
                  `}
                />
              ) : null}



            </div>
            <div className="grid grid-cols-1 gap-x-7 gap-y-3 mx-10 mt-4">
              {details.activity?.disclaimer &&
                details.activity.disclaimer.hasDisclaimer &&
                details.activity.disclaimer.hasDisclaimer === "Show As HTML" ? (
                <div className="alert-secondary">
                  <h4 className="font-semibold">Disclaimer</h4>
                  <div
                    className="html text-sm text-left"
                    dangerouslySetInnerHTML={{
                      __html: `${details.activity?.disclaimer?.disclaimer}`,
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="pt-1 pb-2 mt-9 mx-auto  mx-10">
              <button
                onClick={() =>
                  setAllContent((prevState) => ({
                    ...prevState,
                    peopleGroup: "",
                    company: "",
                    activeSection: "groupSelection",
                    data: {
                      ...prevState.data,
                      peopleGroup: "",
                      partnerId: "",
                      sponsorId: "",
                    },
                  }))
                }
                className="formSubmissionButton bg-textBlack hover:bg-black py-2 px-5 text-xl font-lato text-white mr-4"
              >
                BACK
              </button>
              <button
                className="formSubmissionButton bg-magenta-800 hover:bg-magenta-900 py-2 text-xl font-lato px-5 text-white mr-4"
                type="submit"
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default React.memo(CustomerForm);
